import { onAuthStateChanged } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { DataContext } from '../components/DataProvider';
import NavBar from '../components/navbar/NavBar';
import SettingsPanel from '../components/statistics/SettingsPanel';
import StatisticsPanel from '../components/statistics/StatisticsPanel';
import StyledContainer from '../components/statistics/StyledContainer';
import './StandardStyle.css'; // Import the CSS file

function StatisticsPage() {

    const [loading, setLoading] = useState(true);
    const [loadingStatistics, setLoadingStatistics] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const { settings, statistics, updateSettings, updateStatistics } = useContext(DataContext);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
        } else {
          navigate('/login');
        }
      });
      return () => unsubscribe();
    }, [navigate]);

  
    useEffect(() => {
      if (user) {
        fetch('https://hindi.andreasson.eu/user/settings', {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
        .then((response) => response.json())
        .then((data) => {
          updateSettings({
            englishToHindi: data.engToHindi,
            hindiToEnglish: data.hindiToEng,
            hindiAudioToEnglish: data.hindiAudioToEng,
            numberOfNewWords: data.newWords,
            wordsPerTrainingSession: data.trainingWordCount,
            successRateLimitForNewWords: data.successRateLimitForNewWords,
          });
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });

        fetch('https://hindi.andreasson.eu/user/statistics', {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
        .then((response) => response.json())
        .then((data) => {
          updateStatistics({
            totalWords: data.totalWords,
            newWords: data.newWords,
            learningWords: data.learningWords,
            learntWords: data.learntWords,
            successRate: data.successRate
          });
          setLoadingStatistics(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoadingStatistics(false);
        });
      }
    }, [user]);

    const handleCheckboxChange = (e) => {
      updateSettings({
        ...settings,
        [e.target.name]: e.target.checked,
    });
    };

    const handleSliderChange = (e) => {
      debugger;
      updateSettings({
        ...settings,
        [e.target.name]: e.target.value,
    });
    };

    const saveSettingsAndNavigate = () => {
      if (user) {
      fetch('https://hindi.andreasson.eu/user/settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          engToHindi: settings.englishToHindi,
          hindiToEng: settings.hindiToEnglish,
          hindiAudioToEng: settings.hindiAudioToEnglish,
          newWords: settings.numberOfNewWords,
          trainingWordCount: settings.wordsPerTrainingSession,
          successRateLimitForNewWords: settings.successRateLimitForNewWords,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          navigate('/lesson');
        })
        .catch((err) => {
          setError(err.message);
        });
      }
    };
  
    if (loading || loadingStatistics) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


  return (
    <div className='default-background'>
      <NavBar/>

      <StyledContainer>
      <SettingsPanel
        onCheckboxChange={handleCheckboxChange}
        onSliderChange={handleSliderChange}
      />
      </StyledContainer>
      <StyledContainer>
        <button onClick={saveSettingsAndNavigate}>Start Lesson</button>
      </StyledContainer>
      <StatisticsPanel/>
    </div>
  );
}

export default StatisticsPage;