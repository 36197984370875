import React, { useState, useRef, useCallback, useEffect } from 'react';

import './vocabularyCenter.css';  // Import the CSS file
import './VocabularyPanel.css';  // Import the CSS file

import CardPanel from './CardPanel';

const VocabularyPanel = ({wordsToShow}) => {
    const [words, setWords] = useState(wordsToShow ?? []);
    const [wordStats, setWordStats] = useState(words);
    const roundsLeft = useRef(1);

    // Function to shuffle an array
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
    
    useEffect(() => {
        setWords(shuffleArray(wordStats));
    }, []);

    const submitWords = useCallback(() => {
        console.log('submitWords called');
        debugger;
        const wordsToSubmit = wordStats.map(word => ({
            id: word.id,
            success: word.tryCounter === 0,
            lesson: word.lesson,
        }));

        console.log('Submitting words:', wordsToSubmit);
        console.log('Submitting words string:', JSON.stringify(wordsToSubmit));

        fetch('https://hindi.andreasson.eu/api/words', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(wordsToSubmit)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text();  // Use text() instead of json() to handle empty responses
        })
        .then(text => {
            if (text) {
                try {
                    const data = JSON.parse(text);
                    console.log('Words submitted successfully:', data);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            } else {
                console.log('Empty response from server');
            }
        })
        .catch(error => console.error('Error submitting words:', error));
    }, [words]);


    const cardSuccess = (id, lesson) => {
        setWordStats((words) =>
            words.map((item) => {
                if(item.id === id && item.lesson===lesson){
                    item.success += 1;
                }
                return item;
            })
          );
        
    };
    const cardFailure = (id, lesson) => {
        setWordStats((words) =>
            words.map((item) => {
                if(item.id === id && item.lesson===lesson){
                    item.tryCounter += 1;
                }
                return item;
            })
          );
    };

    const allCardIsDone = () => {
        if(roundsLeft.current > 0){
            const shuffeldWords = shuffleArray(wordStats);
            setWords(shuffeldWords);
            roundsLeft.current -= 1;
        } else {
            submitWords();
        }
    };

    return (

        <CardPanel
            cardSuccess={cardSuccess}
            cardFailure={cardFailure}
            noMoreCards={allCardIsDone}
            words={words}
        />

    );
};

export default VocabularyPanel;