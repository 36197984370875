import React, { useContext, useEffect, useState } from 'react';
import './StandardStyle.css'; // Import the CSS file

import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { DataContext } from '../components/DataProvider';
import TrainingPanel from '../components/Vocabulary/TrainingPanel';
import VocabularyPanel from '../components/Vocabulary/VocabularyPanel';
import NavBar from '../components/navbar/NavBar';


function LessonPage() {

  const [words, setWords] = useState([]);
  const [newWords, setNewWords] = useState([]);
  const [successRate, setSuccessRate] = useState(0.0);
  const { settings } = useContext(DataContext);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);


  useEffect(() => {
    if(!user) return;
    fetch('https://hindi.andreasson.eu/user/statistics', {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      setSuccessRate(data.successRate);
    })
    .catch(error => console.error('Error fetching statistics:', error));
  }, [user]);


  useEffect(() => {
    fetch('https://hindi.andreasson.eu/api/words/new', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
        // Initialize tryCounter to 0 for each word
        const wordsWithCounter = data.map(word => ({ ...word, tryCounter: 0, success: 0 }));
        setNewWords(wordsWithCounter);
    })
    .catch(error => console.error('Error fetching words:', error));
  }, []);

  useEffect(() => {
    // Fetch the words from API
    fetch('https://hindi.andreasson.eu/api/words', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        // Initialize tryCounter to 0 for each word
        const wordsWithCounter = data.map(word => ({ ...word, tryCounter: 0, success: 0 }));
        setWords(wordsWithCounter);
    })
    .catch(error => console.error('Error fetching words:', error));   
  }, []);


  const traningDone = () => {
    const combinedList = [
      ...newWords, ...words.filter(item => !newWords.some(obj => obj.id === item.id))
    ];
    setNewWords([]);
    setWords(combinedList);
  }

  return (
    <div className='default-background'>
        <NavBar/>

        { newWords.length > 0 && ( successRate*100 > settings.successRateLimitForNewWords || words.length < ((settings && settings.wordsPerTrainingSession) || 20))  ? (
          
          <TrainingPanel 
            wordsToShow={newWords}
            traningDone={traningDone}
          />
        ) : (
          <VocabularyPanel 
            wordsToShow={words}
          />
        )}

    </div>
  );
}

export default LessonPage;