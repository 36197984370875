import React, { useContext, useEffect } from 'react';
import { DataContext } from '../DataProvider';
import './SettingsPanel.css'; // Import the CSS file
import StyledContainer from './StyledContainer';

function StatisticsPanel() {

  const { statistics } = useContext(DataContext);

  // Debugging: Log settings to see if they are correctly passed
  useEffect(() => {
    console.log('statistics:', statistics);
  }, [statistics]);

  if (!statistics) {
    // If settings are not loaded, return a loader or null
    //return <div>Loading statistics...</div>;
  }


  return (
    <StyledContainer>
        <div>
          <h1>Statistics</h1>
          <div>
              <label>successRate: {(statistics.successRate * 100).toFixed(1)}% </label>
          </div>

          <div>
              <label>totalWords: {statistics.totalWords} </label>
          </div>

          <div>
              <label>newWords: {statistics.newWords} </label>
          </div>

          <div>
              <label>newWords: {statistics.learningWords} </label>
          </div>

          <div>
              <label>learntWords: {statistics.learntWords} </label>
          </div>
        </div>
    </StyledContainer>
  );
}

export default StatisticsPanel;